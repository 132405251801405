.QuestionFlow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    z-index: 1;
}

@media (min-width: 992px) {
    .QuestionFlow {
        width: auto;
        display: block;
        position: absolute;
    }
}

.QuestionFlow i {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.QuestionFlow i:hover {
    cursor: pointer;
}

.QuestionFlow i.disabled {
    cursor: auto;
    pointer-events: none;
}

.QuestionFlow .QuestionFlow-show-results {
    font-size: 18px;
}