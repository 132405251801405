.QuestionNumber {

}

li.QuestionNumber {
    display: inline-flex;
}

.QuestionNumber .circle {
    display: inline-block;
    padding: 8px;
    margin-left: 2px;
    margin-right: 2px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
}

.QuestionNumber span.disabled {
    background: #ccc;
    border-color: #424242;
    color: #424242!important;
}