.Loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media (min-width: 992px) {
    .Loading {

    }
}