.ExamQuestion {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

@media(min-width: 992px) {
    .ExamQuestion {
        min-height: auto;
    }
}

.ExamQuestion .ExamQuestion-container {

}

.ExamQuestion .ExamQuestion-top {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.ExamQuestion li:hover {
    cursor: pointer;
}

.ExamQuestion .ExamQuestion-mediabox {
    width: 100%;
    max-height: 400px;
}

.ExamQuestion .ExamQuestion-chip {
    flex: 0 0 auto;
}

.ExamQuestion .ExamQuestion-comment {
    position: relative;
}

.ExamQuestion .ExamQuestion-commentImage--hidden {
    display: none;
}

.ExamQuestion .ExamQuestion-commentImage--visible {
    display: block;
}


.ExamQuestion .ExamQuestion-commentSize {
    position: absolute;
    top: -2px;
    left: -4px;
    color: #FF6E40;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
}

.ExamQuestion-results-modal {
    top: 10%!important;
    max-width: 420px;
    bottom: 10%;
    height: fit-content;
}

.ExamQuestion-results-modal .modal-footer{
    display: none;
}

.ExamQuestion .ExamQuestion-button {
    background-color: transparent!important;
    box-shadow: none!important;
}

.ExamQuestion .ExamQuestion-swipe {
    margin-top: 53px;
    min-height: calc(100vh - 70px);
}


@media (min-width: 992px) {
    .ExamQuestion .ExamQuestion-swipe {
        min-height: auto;
        max-width: 768px;
        margin: 0 auto 58px auto;
    }
}


.ExamQuestion .ExamQuestion-questions {
    margin-bottom: 56px;
}