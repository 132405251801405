.MCImage {
  display: inline-block;
  position: relative;
  margin: 0;

  &__image {
    width: 100%;
    height: 100%;
  }

  &__image--cover {
    object-fit: cover;
  }

  &__image--contain {
    object-fit: contain;
  }
}