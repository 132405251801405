.HomeExamResult {
    cursor: pointer;
}

.HomeExamResult .HomeExamResult-container {
    background: white;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    margin-top: 4px;
}

.HomeExamResult .HomeExamResult-bullet {
    display: flex;
    justify-content: center;
    align-items: center;
}

.HomeExamResult .HomeExamResult-score {
    flex: 0 0 40px
}

.HomeExamResult .HomeExamResult-bulletText {
    line-height: 100%;
}

.HomeExamResult .HomeExamResult-summary {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
}

.HomeExamResult .HomeExamResult-title {
    font-size: 18px;
    font-weight: bold;
}

.HomeExamResult .HomeExamResult-location {
    font-weight: lighter;
}

.HomeExamResult .HomeExamResult-startDate {
    font-weight: lighter;
}

.HomeExamResult .HomeExamResult-duration {
    font-weight: bold;
    color: #555;
}