.QuestionNumberPagination {
    position: fixed;
    z-index: 2;
    margin-top: -2px;
}

@media (min-width: 992px) {
    .QuestionNumberPagination {
        position: inherit;
        z-index: 2;
        margin-top: 0;
    }
}

ul.QuestionNumberPagination {
    overflow: auto;
    display: inline-flex;
    width: 100%;
}