.ExamContent {
    display: flex;
    justify-content: center;
}

.ExamContent .collection-item {
    position: relative;
}


.ExamContent .secondary-content {
    position: absolute;
    top: 50%;
    right: 8px;
    bottom: 8px;
    transform:translateY(-50%);
}


.ExamContent .ExamContent__main {
    max-width: 900px;
    width: 100%;
    flex-direction: column;
    height: fit-content;
    margin-right: 8px;
    margin-left: 8px;
}

.ExamContent .ExamContent__adContainer {
    width: 100%;
    height: 130px;
    background: lightsteelblue;
    position: fixed;
    bottom: 0;
    left: 0;
}

.ExamContent .ExamContent__adContainer.ExamContent__adContainer--left {
    display: none;
}

@media screen and (min-width: 1505px), print {
    .ExamContent .ExamContent__adContainer.ExamContent__adContainer--left {
        display: block;
    }
}

@media screen and (min-width: 1220px), print {
    .ExamContent .ExamContent__adContainer {
        width: 300px;
        height: 600px;
        position: relative;
    }

    .ExamContent {
        flex-direction: row;
    }
}