.ExamRow {
  cursor: pointer;
}


@media(min-width: 601px) {
  .ExamRow .ExamRow-container {
    min-height: 142px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

