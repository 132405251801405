.ExamResults {

}

.ExamResults span.circle {
    border-radius: 50%;
}

.ExamResults .ExamResults-durationWrapper {
    display: flex;
    justify-content: center;
}