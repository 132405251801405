.Header .row {
  margin: 0 !important;
}

.Header span {
  padding-left: 5px;
  padding-right: 5px;
}

.Header span.disabled{
  color: #b3b3b3;
}

.Header span.disabled:hover, .Header span.disabled:focus{
  cursor: not-allowed;
  background-color: transparent;
}



.Header .Header-tabs {
  display: flex;
  justify-content: center;
  height: auto;
}

.Header .Header-tab {
  max-width: 125px;
  white-space: normal;
  line-height: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 48px;
}

.Header .Header-tab a {
  height: auto;
  font-size: 12px;
}

@media (min-width: 992px) {
  .Header .Header-tabs {
    height: 48px;
  }
  .Header .Header-tab {
    max-width: 100%;
    white-space: nowrap;
    line-height: 48px;
    height: 48px;
    display: initial;
    min-height: initial;
  }
  .Header .Header-tab a {
    height: 100%;
    font-size: 14px;
  }
}

.Header .Header-container .row .col{
  padding: 0
}

.Header .Header-tabs .indicator {
  background: #ef6c00;
}

.Header .Header-tab a, .Header .Header-tab a:hover{
  color: #ececec;
}

.Header .Header-tab a.active, .Header .Header-tab a.active:hover {
  color: white;
  font-weight: bold;
}

.Header .Header-tab--disabled a {
  cursor: not-allowed!important;
  color: #b3b3b3!important;
}

.Header .bold {
  font-weight: bold;
}

.Header .Toolbar-icon {
  font-size: 35px;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Header .Toolbar {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Toolbar .Toolbar-button {
  box-shadow: none;
  outline: 0;
  border: none;
  background: transparent;
  flex: 0 0 52px;
}

.Toolbar .Toolbar-content {
  flex: 1 1 auto;
  width: 90px;
}

.Toolbar .Toolbar-title {
  font-weight: bold;
  font-size: 24px;
  height: 36px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}

.Toolbar .Toolbar-subtitle {
  line-height: 15px;
  font-size: 15px;
}

@media (min-width: 992px) {

  .Toolbar .Toolbar-button {
    display: none;
  }

  .Toolbar .Toolbar-content {
    text-align: center;
  }

}

.Header .Header-logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Header .Header-logoText {
  font-size: 23px;
  line-height: 100%;
  margin-top: 8px;
}