.HomePage{
}

.HomePage .sticky{
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

/* Tablet: 768px */
/* Desktop: 1024px */
/* Widescreen: 1280px */
/* full HD: 1440px */
@media(max-width: 991px){
  .HomePage {
    padding-top: 50px;
  }

  .HomePage .sticky {
    height: 48px;
  }
}
@media(min-width: 992px) {
  .HomePage {
    padding-top: 120px;
  }

  .HomePage .sticky {
    position: absolute;

  }
}