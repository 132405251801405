.Content {

}

@media (min-width: 992px) {
    .Content {

    }
}

.Content .Content-row {
    margin-bottom: 0;
}


.ExamCategories {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.ExamCategories .ExamCategories__main {
    max-width: 900px;
}

.ExamCategories .ExamCategories__adContainer {
    width: 100%;
    height: 130px;
    background: lightsteelblue;
    position: fixed;
    bottom: 0;
    left: 0;
}

.ExamCategories .ExamCategories__adContainer.ExamCategories__adContainer--left {
    display: none;
}

@media screen and (min-width: 1505px), print {
    .ExamCategories .ExamCategories__adContainer.ExamCategories__adContainer--left {
        display: block;
    }
}

@media screen and (min-width: 1220px), print {
    .ExamCategories .ExamCategories__adContainer {
        width: 300px;
        height: 600px;
        position: relative;
    }

    .ExamCategories {
        flex-direction: row;
    }
}