.TopicExamInfoModal {
    top: 10%!important;
    max-width: 420px;
    bottom: 10%;
    height: fit-content;
}

.TopicExamInfoModal .TopicExamInfoModal-container {

}

.TopicExamInfoModal .TopicExamInfoModal-header {
    background: #FFA000;
    display: flex;
    align-items: center;
    min-height: 70px;
    justify-content: center;
    padding: 16px;
}

.TopicExamInfoModal .TopicExamInfoModal-content {
    padding: 16px;
}

.TopicExamInfoModal .TopicExamInfoModal-description {
    text-align: center;
}


.TopicExamInfoModal .TopicExamInfoModal-headerTitle {
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
}

.TopicExamInfoModal h4 {
    display: none;
}

.TopicExamInfoModal .modal-content {
    padding: 0;
}

.TopicExamInfoModal .modal-footer {
    display: none;
}

.TopicExamInfoModal-label {
    font-weight: bold;

}
