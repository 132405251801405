@mixin tablet() {
  @media screen and (min-width: 768px), print {
    @content
  }
}
.MainPage {

  * {
    color: #555555;
  }
  // HEADER

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    padding: 0 8px;
    height: 60px;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  &__header--shadow {
    box-shadow: 1px 1px 5px rgba(15, 32, 84, 0.5);
  }

  &__headerLeft {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__logo {
    width: 40px;
    height: 40px;
  }

  &__brand {
    align-self: center;
    color: #009688;
    font-size: 20px;
    line-height: 100% ;
    margin-top: 6px;
  }

  // Main


  &__main {
    margin-top: 60px;
  }

  // Common

  &__title {
    font-size: 30px;
    text-align: center;
    margin-bottom: 10px;

    @include tablet {
      font-size: 42px;
      margin-bottom: 15px;
    }
  }

  &__title--bold {
    font-weight: bold;
  }

  &__description {
    text-align: center;
    margin-bottom: 8px;
    font-size: 14px;

    @include tablet {
      font-size: 16px;
      margin-bottom: 16px;
    }
  }
  &__image {
    max-width: 228px;

    @include tablet {
      max-width: 100%;
      width: 250px;
    }
  }

  // Future

  &__future {
    background: white;
    padding: 8px;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include tablet {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      padding: 16px;
      min-height: 70vh;
    }
  }

  &__futureLeft {
    display: flex;
    flex-direction: column;
    @include tablet {
      margin-right: 80px;
    }
  }


  &__futureRight {
    display: flex;
    justify-content: center;
  }


  // Practice

  &__practice {
    background: #009688;
    padding: 8px;
    color: white;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include tablet {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row-reverse;
      padding: 16px;
      min-height: 70vh;
    }
  }

  &__practice * {
    color: white;
  }

  &__practiceLeft {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include tablet {
      margin-left: 80px;
    }
  }

  &__practiceRight {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  // Comments


  &__comment {
    background: white;
    padding: 8px;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include tablet {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      padding: 16px;
      min-height: 70vh;
    }
  }

  &__commentLeft {
    display: flex;
    flex-direction: column;

    @include tablet {
      margin-right: 80px;
    }
  }

  &__commentRight {
    display: flex;
    justify-content: center;
  }


  // Start

  &__start {
    background: #009688;
    padding: 8px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;

    @include tablet {
      min-height: 70vh;
    }
  }

  &__start * {
    color: white;
  }

  &__startButton {
    background: white;
    text-decoration: none;
    padding: 8px;
    color: #009688;
    font-size: 18px;
    box-shadow: 1px 1px 5px rgba(15, 32, 84, 0.5);
    min-width: 100%;
    text-align: center;

    @include tablet {
      min-width: 100px;
      width: 100%;
      max-width: 300px;
    }
  }

  &__startButton:hover, &__startButton:active {
    cursor: pointer;
    box-shadow: none;
  }

  &__startButton--top {
    min-width: initial;
  }

  &__startContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__google {
    width: 180px;
  }

  &__footer {
    padding: 80px 0 0 0 ;

    @include tablet {
      padding: 20px 0 0 0;
      display: flex;
      flex-direction: column;
    }
  }

  &__footerSection {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }

  &__footerSection a {
    text-decoration: underline;
    cursor: pointer;
    @include tablet {
      margin: 0 12px;
    }
  }

  &__footerSection a:hover, &__footerSection a:focus {
    color: #009688;
  }

  &__footerSection--logo {
    margin-bottom: 20px;

    @include tablet {
      margin: 0;
      align-self: flex-start;
    }
  }

  &__footerSection--copyright {
    font-size: 12px;
    margin-top: 40px;
    @include tablet {
      margin: 0;
    }
  }

  &__footerMain {
    @include tablet {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__footerLinks {
    @include tablet {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      margin-top: 12px;
    }

  }
}
