.PageWrapper {
    min-height: 100vh;
}

.PageWrapper .PageWrapper-header {
    position: fixed;
    width: 100vw;
    top: 0;
    z-index: 2;
}

.PageWrapper .PageWrapper-container {
    padding-top: 50px;
    position: relative;
}

.PageWrapper .PageWrapper-header--toolbar ~ .PageWrapper-container {
    padding-top: 70px;
}


@media (min-width: 992px) {
    .PageWrapper .PageWrapper-container  {
        padding-top: 130px;
    }
}


.PageWrapper .footer-copyright .container {
    color: white!important;
}

@media (min-width: 1220px) {
    .PageWrapper .PageWrapper-header {
        position: initial;
    }

    .PageWrapper .PageWrapper-container {
        padding-top: 0;
    }


    .PageWrapper .PageWrapper-header--toolbar ~ .PageWrapper-container {
        padding-top: initial;
        min-height: calc(100vh - 134px - 199px);
    }

    .PageWrapper .PageWrapper-header--head ~ .PageWrapper-container {
        min-height: calc(100vh - 112px - 199px);
    }
}


.PageWrapper__footer {
    display: none;
}

@media (min-width: 1220px) {
    .PageWrapper__footer {
        display: block;
    }
}

